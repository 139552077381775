.cursor {
  display: flex;
  pointer-events: none;

  &__ball {
    position: fixed;
    top: 0;
    left: 0;
    mix-blend-mode: difference;
    z-index: 1000;

    circle {
      fill: #ffffff;
    }
  }
}
