@import '../../sass/global.module';

.navigation {
  width: 100%;
  padding-left: 1em;
  padding-right: 1em;
  cursor: default;
  // position: fixed;
  // background-color: #fff0;
  z-index: 3;
  display: flex;
  flex-direction: row;
  // justify-content: flex-end;
  &.dark {
    @include mode-colors('dark');
  }
  &.light {
    @include mode-colors('light');
  }
  div {
    gap: 1em;
  }
  a {
    font-family: $work-font-family;
    font-size: $font-size-p-m;
    font-weight: $extra-thin;
    padding: 0;
    text-align: end;
    &:hover {
      text-decoration: overline 1px;
      cursor: pointer;
    }
    &:focus {
      font-weight: $thin;
      text-decoration: overline 1px;
    }
  }
  button {
    // color: $text-dark-bg;
    border: none;
    &:focus {
      box-shadow: none;
    }
  }
  .nav-link.active {
    color: $text-dark-bg;
  }
  .material-symbols-rounded {
    font-variation-settings: 'FILL' 0, 'wght' 100, 'GRAD' -25, 'opsz' 48;
    font-size: 35px;
    padding: 0;
  }
}

#logo {
  font-family: $work-font-family;
  font-weight: $extra-thin;
  font-size: $font-size-p-lg;
  // position: absolute;
  // top: -3em;
  // left: $padding-full-vw * - 1.2;
  zoom: 15%;
}
@media screen and (max-width: 575px) {
  #logo {
    font-size: $font-size-h3;
    padding: 0;
  }
  .navbar-toggler {
    padding: 0;
  }
}
