@import "../App/sass/global.module";

#body {
  background-color: $background-color;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  cursor: none;
}

.main-container {
  font-family: $outfit-font-family;
  &.dark {
    @include mode-colors("dark");
  }
  &.light {
    @include mode-colors("light");
  }
}

.material-symbols-rounded {
  font-size: 2rem;
  &:hover {
    font-variation-settings: "FILL" 1;
  }
}
