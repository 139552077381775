#dark-mode {
  position: fixed;
  bottom: 1em;
  left: 1em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // &:hover {
  //   // cursor: pointer;
  // }
}

#switch {
  width: 60px;
  height: 30px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // &:hover {
  //   // cursor: pointer;
  // }
  &.dark {
    background-color: #727271;
  }
  &.light {
    background-color: #dfe0db;
  }
}
#toggler {
  margin: 4%;
  height: 23px;
  width: 23px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #ffffff;
  border-radius: 23px;
  position: relative;
  // &:hover {
  //   // cursor: pointer;
  // }
  &.light {
    left: 30px;
  }
  .material-symbols-rounded {
    font-size: 1.3rem;
    font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
    position: relative;
    color: black;
    
    &.night {
      top: 0px;
      left: 1px;
    }
    &.day {
      top: 0px;
      left: 0px;
    }
  }
}

