//// Variables
// Fonts
$outfit-font-family: 'Outfit', sans-serif;
$work-font-family: 'Work Sans', sans-serif;
$font-size-h1: calc(25px + 5vw);
$font-size-h2: calc(20px + 3vw);
$font-size-h3: calc(18px + 2vw);
$font-size-h4: calc(15px + 1vw);
$font-size-h5: calc(12px + 1vw);
$font-size-p-lg: calc(18px + 0.8vw);
$font-size-p-m: calc(15px + 0.5vw);
$font-size-p-ms: calc(12px + 0.5vw);
$font-size-p-sm: calc(12px + 0.3vw);
$font-size-p-xs: calc(10px + 0.3vw);
$extra-thin: 300;
$thin: 300;
$medium: 500;
$bold: 800;
$extra-bold: 900;

// Colors
$background-color: #101010;
$dark-background-color: #202020;
$text-dark-bg: #f9f9f9;
$light-background-color: #f9f9f9;
$text-light-bg: #202020cd;
$accent-color-dark: #745647;
$accent-color-dark-invert: #8ba9b8;
$accent-color-light: #3a56e0;
$accent-color-light-invert: #c5a91f;
// Padding
$padding-full-vw: 2em;

// Margins

// Widths
$full-vw: 100%;

// Heights
$full-vh: 100vh;

$mode: 'light';

@mixin mode-colors($mode) {
  .clicker-container {
    .arrow-clicker {
      position: relative;
      bottom: 4em;
      p {
        padding-bottom: 0.5em !important;
      }
    }
  }
  .section {
    display: flex;
    width: $full-vw;
    min-height: $full-vh;
    padding: 0 1em 0 1em;
    overflow-x: hidden;
  }
  .section-title {
    transition: 0.3s;
    margin-left: 5%;
    gap: 1em;
    display: flex;
    width: 100%;
    font-size: 1.3em;
    flex-wrap: wrap;
    h2 {
      font-size: $font-size-h2;
    }
  }
  .section-1 {
    min-height: $full-vh;
    width: $full-vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    .section-1-text {
      padding: 0 2em;
      width: 100%;
      p {
        text-align: justify;
      }
    }
    .img-container {
      align-self: center;
      zoom: 2;
    }
    &.extended {
      .img-container {
        height: 40vh;
        padding-top: 10vh;
      }
      .section-1-text {
        overflow: overlay;
      }
    }
  }
  .section-2 {
    width: $full-vw;
    min-height: $full-vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    .section-2-text {
      font-size: $font-size-h5;
      .accent {
        margin-right: 1rem;
      }
      p {
        text-align: left;
      }
    }
    .section-2-pretty {
      margin-left: 2%;
      margin-top: 15%;
    }
  }
  .section-3 {
    max-width: 95%;
    min-height: 70vh;
    overflow-x: hidden;
    overflow-y: scroll;
    border: 0.2em solid;
    border-radius: 25px;
    padding: 0 2em;
    .name_email {
      display: contents;
    }
    div {
      width: 99%;
      overflow: hidden;
      margin: auto;
    }
  }
  .grommet-theme {
    .form_container {
      label {
        font-size: calc(16px + 1vw);
        margin-top: 5%;
        font-weight: 750;
        line-height: 2;
      }
      font-size: $font-size-h3;
      textarea {
        font-size: $font-size-h5;
        height: 30vh;
      }
      button {
        font-size: $font-size-h3;
      }
      .form_buttons {
        width: 100vw;
        display: grid;
        .grommit_button {
          margin-left: 0.5em;
          padding: 2%;
          margin-top: 1rem;
          margin-bottom: 2rem;
        }
      }
    }
  }
  .footer-distributed {
    margin-top: 5em;
    padding: 3em 3em 2em 3em;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    .footer-company-name {
      font-size: 0.8em;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
    a {
      cursor: inherit;
    }
    .footer-center,
    .footer-left,
    .footer-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 20%;
    }
    .footer-center {
      div {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 1em;
      }
      i {
        font-size: 17px;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        text-align: center;
        line-height: 42px;
        margin: 10px 15px;
        vertical-align: middle;
      }
      p {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        span {
          display: block;
          font-weight: normal;
          font-size: 14px;
          line-height: 2;
        }
        a {
          text-decoration: none;
        }
      }
    }
    .footer-right {
      gap: 1em;
      .footer-icons {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
      }
    }
    .footer-left {
      .footer-links a:before {
        content: '|';
        font-weight: 250;
        font-size: $font-size-h5;
        left: 0;
        display: inline-block;
        padding-right: 0.5em;
        padding-left: 0.2em;
      }
      .footer-links {
        font-size: 1.2em;
        a {
          display: inline-block;
          text-decoration: none;
          color: inherit;
        }
      }
    }
  }
  @if $mode== 'light' {
    background-color: $light-background-color;
    color: $text-light-bg;
    transition: 0.2s;
    a {
      color: $text-light-bg;
      text-decoration: none;
    }
    button {
      background: $light-background-color;
      color: $text-light-bg;
      &.primary {
        background: $accent-color-light;
        color: $text-dark-bg;
      }
    }
    .navigation .material-symbols-rounded {
      color: $text-light-bg;
    }
    .animate-play circle {
      fill: #c1d20076;
      // opacity: 0.5;
    }
    .accent {
      color: $accent-color-light;
    }
    #logo {
      filter: invert(1);
      fill: $text-light-bg;
    }
    #about {
      circle {
        fill: $text-light-bg;
      }
    }
    .section-3 {
      border-color: $accent-color-light-invert;
    }
    .grommet-theme {
      .form_container {
        label {
          color: $accent-color-light;
        }
      }
    }
    .footer-distributed {
      border-top: $accent-color-light-invert 2px solid;
      .footer-center {
        i {
          background-color: $accent-color-light-invert;
          color: $text-light-bg;
        }
        a {
          color: $accent-color-light-invert;
        }
      }
      .footer-left {
        .footer-links a:before {
          content: '|';
          color: $text-light-bg;
        }
        .footer-links {
          color: $accent-color-light-invert;
        }
      }
    }
  } @else {
    background-color: $dark-background-color;
    color: $text-dark-bg;
    transition: 0.2s;
    a {
      color: $text-dark-bg;
      text-decoration: none;
    }
    button {
      background-color: $dark-background-color;
      color: $text-dark-bg;
      &.primary {
        background: $accent-color-dark;
        color: $text-light-bg;
      }
    }
    .accent {
      color: $accent-color-dark;
    }
    .navigation .material-symbols-rounded {
      color: $text-dark-bg;
    }
    .animate-play circle {
      // TODO: standarize
      fill: #fdc3d277;
    }
    #about {
      circle {
        fill: $text-dark-bg;
      }
    }
    .section-3 p {
      color: fade-out($color: $text-dark-bg, $amount: 0.2);
      background-color: lighten($color: $dark-background-color, $amount: 3);
    }

    .footer-distributed {
      background-color: rgba(0, 0, 0, 0.2);
      .footer-center {
        i {
          background-color: $accent-color-dark-invert;
          color: $text-light-bg;
        }
        a {
          color: $accent-color-dark-invert;
        }
      }
      .footer-left {
        .footer-links a:before {
          content: '|';
          color: $text-dark-bg;
        }
        .footer-links {
          color: $accent-color-dark-invert;
        }
      }
    }
  }
}

#grommet :export {
  mode: $mode;
}

// @media screen and (-webkit-min-device-pixel-ratio:0) and (max-width: 768px) {
//   .section-2 {
//     background-color: red;

//     .section-2-pretty {
//       div :first-of-type {
//         background-color: red;
//         margin-left: 30%;
//       }
//     }

//   }
// }
@media screen and (max-width: 4000px) and (min-width: 1851px) {
  .section-3 {
    zoom: 50%;
  }
  .footer-center {
    // padding-left: 7.5%;
    width: 15% !important;
  }
}
@media screen and (max-width: 1850px) and (min-width: 1000px) {
  .section-2-pretty {
    width: 100%;
    zoom: 55%;
    div :first-of-type {
      max-width: 2200em;
    }
  }
  .section-3 {
    zoom: 70%;
  }
  .footer-center {
    // padding-left: 7.5%;
    width: 30% !important;
  }
  .footer-right {
    flex-grow: 0.5;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .section-1 {
    .img-container {
      // width: %;
      // margin: 0;
      margin-left: 0.5em;
    }
    .section-1-text {
      width: 200vw !important;
      p {
        text-align: justify;
      }
    }
  }
  .footer-center {
    // padding-left: 7.5%;
    width: 30% !important;
  }
  .footer-right {
    flex-grow: 0.5;
  }
}
@media screen and (max-width: 1024px) {
  .section-2 {
    flex-direction: column !important;
    padding: 0 1em;
    margin-bottom: 10%;
    .section-2-pretty {
      height: 50vh !important;
      zoom: 54%;
      margin-left: 15% !important;
    }
  }
  .section-3 {
    padding: 0 0 0 0 !important;
  }
}
//Tablet
@media screen and (max-width: 768px) {
  #logo {
    position: relative;
    right: 15%;
  }
  .intro {
    p {
      width: 88vw;
    }
  }
  .section-1 {
    flex-direction: column !important;

    &.extended {
      .section-1-text {
        width: 100vw !important;
        margin: 25% 0 0 0;
      }
      .img-container {
        padding-top: 5vh !important;
        height: 30vh !important;
      }
    }
    .img-container {
      height: 21vh !important;
      margin-top: 10%;
    }
    .section-1-text {
      width: 70vw !important;
      @supports (font: -apple-system-body) and (-webkit-appearance: none) {
        margin-top: 50%;
      }
    }
  }
  .section-2 {
    .section-2-pretty {
      div :first-of-type {
        max-width: 80em;
        position: relative;
        margin-top: 5%;
      }
      @supports (font: -apple-system-body) and (-webkit-appearance: none) {
        div :first-of-type {
          margin-left: 30%;
        }
      }
    }
  }
  .section-3 {
    .name_email {
      flex-direction: column;
    }
  }
  .footer-center {
    // padding-left: 7.5%;
    width: 40% !important;
    div {
      display: flex;
      text-align: initial;
    }
  }
  .footer-right {
    text-align: left;
  }
}
//Mobile
@media screen and (max-width: 521px) {
  .section-1 {
    &.extended {
      .img-container {
        height: 14vh !important;
        padding-top: 2vh !important;
      }
      .section-1-text {
        padding: 0 1em !important;
        margin: 35% 0 0 0;
      }
    }
    .section-1-text {
      width: 100%;
    }
  }
  .section-2 {
    min-height: 80vh !important;
    .section-2-text {
      position: relative;
      bottom: 6em;
      margin-bottom: -5em !important;
    }
    .section-2-pretty {
      @supports (font: -apple-system-body) and (-webkit-appearance: none) {
        div :first-of-type {
          margin-left: 43% !important;
          // background: red;
        }
      }
      div :first-of-type {
        max-width: 42em;
        margin-left: 30%;
        position: relative;
      }
    }
  }
  .footer-distributed {
    flex-direction: column-reverse;
    padding: 0 1em 0 1em !important;

    .footer-left {
      flex-direction: row !important;
      width: 100% !important;
      margin-bottom: 10% !important;
      align-items: flex-end !important;
      // justify-content: space-between !important;
      a :first-of-type {
        margin-bottom: 1.5em;
      }
      .footer-links {
        position: relative;
        bottom: 1em;
        width: 10em;
      }
      .footer-company-name {
        position: absolute;
      }
    }
    .footer-center {
      flex-direction: row !important;

      width: 100% !important;
      margin-bottom: 5% !important;
      margin-top: 5% !important;
      div {
        text-align: center !important;
        justify-content: center;
        align-items: baseline !important;
      }
    }
    .footer-right {
      // flex-direction: row !important;
      margin-bottom: 10% !important;
      margin-top: 5% !important;

      width: 100% !important;
    }
  }
}
