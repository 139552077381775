@import '../../sass/global.module';

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  font-family: $work-font-family;
  overflow-x: hidden;
  p {
    font-size: $font-size-p-m;
    font-weight: $extra-thin;
    // text-align: start;
  }
  a {
    border-bottom: 1px solid;
    &:hover {
      cursor: none;
    }
  }
}
.clicker-container {
  display: flex;
  justify-content: center;
}
.read-more {
  animation: fadeIn 1s;
  animation-fill-mode: forwards;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 690px) {
  .about {
    .lottie {
      zoom: 50%;
    }
  }
}
